export default {
  white: '#FFFFFF',
  brandRed: '#EF4129',
  redOrange: '#F56E43',
  error: '#B41107',
  lightMint: '#94EADF',

  // coral
  coral1: '#FEDCD1',
  coral2: '#FCB6A0',
  coral3: '#F67E6D',

  // blue
  blue1: '#D5EEF9',
  blue2: '#50B4ED',
  blue3: '#1387C8',

  // gray
  gray: '#BFC4CA',
  gray1: '#F6F7F9',
  gray2: '#CFD7E2',
  gray3: '#A4B1C1',
  gray4: '#707C8B',
  gray5: '#464C53',
}
