export const EMPTY_STRING = '';

export type Img = {
  url: string;
  fileName: string;
};

export type RouteParams = {
  pageId: string;
};

export type Page = {
  id: string;
  additional_info?: string;
};

export type InfoWithKey = {
  key: string;
  title: string;
  image: Img | typeof EMPTY_STRING;
};

export type UpdateInfoData = {
  field: string;
  value: string | Img | Img[];
};

export type AdditionalInfo = {
  title: string;
  description: string;
  images?: Array<Img | ''>;
  images_1?: Array<Img | ''>;
  images_2?: Array<Img | ''>;
  image?: Img | typeof EMPTY_STRING;
  amenities?: string;
  lifestyle?: string;
  overview?: string;
  info?: InfoWithKey[];
  key?: string;
};

export type UpdateImagesFunc = (
  additionalInfo: AdditionalInfo,
  idx: number,
  data: Img
) => AdditionalInfo;

export type UploadHandler = (
  data: Img,
  infoIdx: number,
  imgIdx: number
) => void;

export type AdditionalInfoWithoutImg = {
  title: string;
  description: string;
  amenities?: string;
  lifestyle?: string;
  overview?: string;
  key?: string;
};

export interface FormData {
  title: string;
  description: string;
  image?: Img | null;
  additional_info?: AdditionalInfo[];
  isNotExpand?: boolean;
}
