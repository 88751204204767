import React, { useState } from 'react';

// components
import { Button, Form, InputOnChangeData } from 'semantic-ui-react';

interface LinkInputProps {
  index?: number;
  text: string;
  onChange: (text: string, index?: number) => void;
}

const LinkInput: React.FC<LinkInputProps> = ({
  index,
  text,
  onChange,
}: LinkInputProps) => {
  const [showLinkInput, setShowLinkInput] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');

  const handleLinkBtnClick = () => {
    setShowLinkInput(!showLinkInput);
  };

  const handleLinkChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    const { value } = data;
    setLink(value);
  };

  const addLinkToDescription = () => {
    const selectText = window.getSelection()?.toString();
    if (!!selectText && !!link) {
      const linkTag = `<link=${link}>${selectText}</link>`;
      onChange(text.toString().replace(selectText, linkTag), index);
      setShowLinkInput(false);
    }
  };

  const linkStyle = {
    top: 0,
    left: 120,
    width: '100%',
    height: '100%',
    display: 'flex',
  };

  return (
    <div style={{ position: 'relative', marginBottom: '20px' }}>
      <Button primary size="medium" onClick={handleLinkBtnClick}>
        {showLinkInput ? 'Hide link' : 'Add link'}
      </Button>
      {showLinkInput && (
        <div style={{ ...linkStyle, position: 'absolute' }}>
          <Form.Input
            style={{ height: '36px', width: '300px', marginRight: '10px' }}
            name="link"
            value={link}
            fluid={true}
            placeholder="URL"
            onChange={handleLinkChange}
          />
          <Button
            primary
            disabled={!link}
            size="medium"
            onClick={addLinkToDescription}
          >
            OK
          </Button>
          <div
            style={{
              width: '200px',
              marginLeft: '20px',
              fontSize: '16px',
              color: 'gray',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Enter url and select text
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkInput;
