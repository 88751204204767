import React from 'react';
import { Select, DropdownProps } from 'semantic-ui-react';

type Data = {
  key: string;
  value: string;
  text: string;
};

interface SelectType {
  defaultValue?: string;
  data: Data[];
  onChange: (value: string) => void;
  placeholder?: string;
}

function SelectType(props: SelectType) {
  const handleChange = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    option: DropdownProps
  ) => {
    props.onChange(option.value as string);
  };

  return (
    <Select
      value={props.defaultValue}
      placeholder={props.placeholder ? props.placeholder : ''}
      options={props.data}
      onChange={handleChange}
    />
  );
}

export default SelectType;
