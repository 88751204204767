/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// hooks
import { useForm } from 'src/hooks/useForm';

// components
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';
import MainLayout from 'src/layouts/MainLayout';

import { getSetting, updateSetting } from 'src/services/api';

type RouteParams = {
  settingId: string;
};

type Obj = {
  [K: string]: string;
};

type Contacts = Obj & {
  id: string;
  address: string;
  email: string;
  phones: string[];
};

type SocialLinks = Obj & {
  id: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  linkedin?: string;
  twitter?: string;
  pintrest?: string;
};

type FormData = Contacts | SocialLinks;

const Setting: React.FC = () => {
  const history = useHistory();
  const { settingId } = useParams<RouteParams>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formData, updateFormData, _, updateForm] = useForm<FormData | null>(
    null
  );

  const getSettingData = async () => {
    const data = await getSetting(settingId);
    const formData = data.data() as FormData;
    updateForm({ ...formData });
  };

  useEffect(() => {
    getSettingData();
  }, []);

  async function update() {
    await updateSetting(settingId, formData);
    history.goBack();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeData = (data: any, idx?: number) => {
    const { name, value } = data;
    const currentValue = formData[name] as string | string[];
    let newValue;
    if (currentValue instanceof Array && idx !== null && idx !== undefined) {
      newValue = [...currentValue];
      newValue[idx] = value;
    } else {
      newValue = value;
    }

    updateForm({
      ...formData,
      [name as string]: newValue,
    });
  };

  const firstToUpperCase = (str: string): string =>
    str[0].toUpperCase() + str.slice(1);

  const renderFormItem = (field: string, value: string, idx?: number) => {
    return (
      <Form.Input
        key={`setting_${field}_${idx ? idx : 0}`}
        label={firstToUpperCase(field)}
        value={value}
        onChange={(_: any, data: any) => handleChangeData(data, idx)}
        name={field}
        fluid={true}
        placeholder={firstToUpperCase(field)}
      />
    );
  };

  return (
    <MainLayout>
      <Segment>
        <Header as="h2">Settings</Header>
        <Grid>
          <Grid.Column width={6}>
            <Segment>
              <Header as="h2">{firstToUpperCase(settingId)}</Header>
              {!!formData && (
                <Form size="large">
                  {Object.keys(formData).map((field) =>
                    (formData[field as keyof FormData] as unknown) instanceof
                    Array ? (
                      <>
                        {(formData[
                          field as keyof FormData
                        ] as any).map((item: string, i: number) =>
                          renderFormItem(field, item, i)
                        )}
                      </>
                    ) : (
                      renderFormItem(field, formData[field as keyof FormData])
                    )
                  )}
                  <Button primary size="large" onClick={update}>
                    Update
                  </Button>
                </Form>
              )}
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    </MainLayout>
  );
};

export default {
  component: Setting,
  exact: false,
  public: false,
  path: '/setting/:settingId',
};
