import * as React from 'react';
import { useHistory } from 'react-router-dom';

// components
import { Button, Table } from 'semantic-ui-react';

// theme
import MainLayout from 'src/layouts/MainLayout';
import { SyntheticEvent, useEffect, useState } from 'react';
import { getPages } from 'src/services/api';

// interfaces
interface AuthForm {
  email: string;
  password: string;
}

function Pages() {
  const history = useHistory();
  const [pages, setPages] = useState([]);

  useEffect(() => {
    (async function () {
      const data = await getPages();
      if (data && data.docs && data.docs.length) {
        const result = data.docs.map((item) => {
          return { ...item.data(), id: item.id };
        });
        setPages(result);
      }
    })();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function editPage(e: SyntheticEvent, data: any) {
    history.push('/page/' + data.page.id);
  }

  return (
    <MainLayout isLoading={!pages.length}>
      <Table fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Page</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {!!pages.length &&
            pages.map((page) => {
              return (
                <Table.Row key={page.id}>
                  <Table.Cell>{page.id}</Table.Cell>
                  <Table.Cell>{page.title}</Table.Cell>
                  <Table.Cell>{page.description}</Table.Cell>
                  <Table.Cell>
                    <Button page={page} onClick={editPage}>
                      Edit
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </MainLayout>
  );
}

export default {
  component: Pages,
  exact: false,
  public: false,
  path: '/pages',
};
