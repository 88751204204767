/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// components
import { Button, Table } from 'semantic-ui-react';
import MainLayout from 'src/layouts/MainLayout';

import {
  getTeamMembers,
  createTeamMember,
  deleteTeamMember,
} from 'src/services/api';
import { TeamMember } from '../../interfaces/api';

const DESCRIPTION_MAX_SIZE = 80;

const EMPTY_MEMBER: TeamMember = {
  name: '',
  position: '',
  description: '',
  image: null,
  isOwner: false,
  contacts: {
    email: '',
    phone: '',
  },
  social: {
    facebook: '',
    linkedin: '',
    instagram: '',
    twitter: '',
  },
  additional_info: [],
};

const Team: React.FC = () => {
  const history = useHistory();
  const [members, setMembers] = useState<TeamMember[]>([]);

  useEffect(() => {
    (async function () {
      const data = await getTeamMembers();
      if (data && data.docs && data.docs.length) {
        const result = data.docs.map((item) => {
          return { ...item.data(), id: item.id } as TeamMember;
        });
        setMembers(result);
      }
    })();
  }, []);

  function editMember(e: SyntheticEvent, data: any) {
    history.push(`/team_member/${data.member.id}`);
  }

  const removeMember = async (_: SyntheticEvent, data: any) => {
    await deleteTeamMember(data.member.id);
    window.location.reload();
  };

  const createNewMember = async () => {
    const { id } = await createTeamMember(EMPTY_MEMBER);
    if (id) {
      history.push(`/team_member/${id}`);
    }
  };

  return (
    <MainLayout isLoading={!members.length}>
      <Table fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Member</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Position</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {!!members.length &&
            members.map((member, i) => {
              return (
                <Table.Row key={member.id}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>{member.name}</Table.Cell>
                  <Table.Cell>{member.position}</Table.Cell>
                  <Table.Cell>
                    {member.description.slice(0, DESCRIPTION_MAX_SIZE)}...
                  </Table.Cell>
                  <Table.Cell>{member.contacts.phone}</Table.Cell>
                  <Table.Cell>{member.contacts.email}</Table.Cell>
                  <Table.Cell>
                    <Button member={member} onClick={editMember}>
                      Edit
                    </Button>
                    <Button color="red" member={member} onClick={removeMember}>
                      Remove
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      <div style={{ marginTop: '20px' }}>
        <Button primary size="large" onClick={createNewMember}>
          Create
        </Button>
      </div>
    </MainLayout>
  );
};

export default {
  component: Team,
  exact: false,
  public: false,
  path: '/team',
};
