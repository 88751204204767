import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

// hooks
import { AuthorizationContext } from 'src/contexts/authorization';
import { useForm } from 'src/hooks/useForm';
import Storage from 'src/services/storage';

// components
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'

// theme
import colors from 'src/styles/colors'
import logo from 'src/assets/icons/logo.svg'

// interfaces
interface AuthForm {
  email: string;
  password: string;
}

function Login() {
  const history = useHistory();
  const [showVideo, setShowVideo] = React.useState(window.innerWidth > 1024)
  const { signIn, signInWithProvider } = React.useContext(AuthorizationContext);
  const [formData, updateFormData] = useForm({
    email: '',
    password: '',
  });
console.log('LOG ::::::> formData <::::::',formData)
  React.useEffect(() => {
    window.addEventListener('resize', resize)

    return () => {
        window.removeEventListener('resize', resize)
    }
  }, [showVideo])

  function resize () {
    if (window.innerWidth < 1024 && showVideo) {
      setShowVideo(false)
    } else if (window.innerWidth >= 1024 && !showVideo)
      setShowVideo(true)
  }

  const _signIn = () => {
    signIn(formData.email, formData.password);
  };

  return (
    <Grid textAlign='center' style={{ height: '100vh', margin: 0, background: colors.gray1}}  verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' style={{ color: colors.brandRed}} textAlign='center'>
          <img src={logo} alt="" style={{ width: 184, height: 36 }}/> CMS
        </Header>
        <Form size='large' style={{ marginTop: 50 }}>
          <Segment stacked>
            <Form.Input onChange={updateFormData} name="email" fluid={true} icon='user' iconPosition='left' placeholder='E-mail' />
            <Form.Input
              fluid={true}
              icon='lock'
              name="password"
              onChange={updateFormData}
              iconPosition='left'
              placeholder='Password'
              type='password'
            />

            <Button
              fluid={true}
              size='large'
              onClick={_signIn}
              style={{ background: colors.brandRed, color: 'white' }}
            >
              Log in
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

export default {
  component: Login,
  exact: true,
  public: true,
  path: '/',
};
