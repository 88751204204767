import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// hooks
import { useForm } from 'src/hooks/useForm';

// components
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';

import MainLayout from 'src/layouts/MainLayout';
import { getComment, updateComment } from 'src/services/api';
import { FileUpload, DatePicker, SelectType } from 'src/components';

const selectTypesData = [
  {
    key: 'buyer',
    value: 'buyer',
    text: 'Buyer',
  },
  {
    key: 'seller',
    value: 'seller',
    text: 'Seller',
  },
];

type Img = {
  url: string;
  fileName: string;
};

interface FormData {
  title?: string;
  description?: string;
  image?: Img;
  date?: string;
  type: string;
}

type RouteParams = {
  commentId: string;
};

type Comment = {
  id: string;
};

function Comment() {
  const history = useHistory();
  const { commentId } = useParams<RouteParams>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formData, updateFormData, _, updateForm] = useForm<FormData>({
    title: '',
    description: '',
    image: undefined,
    date: '',
    type: '',
  });

  const timestampToString = (sec: number) => new Date(sec * 1000).toString();

  useEffect(() => {
    (async function () {
      const data = await getComment(commentId);
      const formData = data.data();

      updateForm({
        title: formData.title,
        description: formData.description,
        image: formData.image,
        date: formData.date ? timestampToString(formData.date.seconds) : '',
        type: formData.type,
      });
    })();
  }, []);

  async function update() {
    await updateComment(commentId, formData);
    history.goBack();
  }

  function handleOnUpload(url: string, fileName: string) {
    if (!url) {
      updateForm({
        ...formData,
        image: null,
      });
      updateComment(commentId, { ...formData, image: null });
    } else {
      updateForm({
        ...formData,
        image: {
          url,
          fileName,
        },
      });

      updateComment(commentId, {
        ...formData,
        image: {
          url,
          fileName,
        },
      });
    }
  }

  const handleChangeDate = (date: string) => {
    updateForm({
      ...formData,
      date,
    });
  };

  const handleChangeType = (type: string) => {
    updateForm({
      ...formData,
      type,
    });
  };

  return (
    <MainLayout>
      <Segment>
        <Header as="h2">Main content</Header>
        <Grid>
          <Grid.Column width={10}>
            <Segment>
              <Header as="h2">Comment</Header>
              <Form size="large">
                <Form.Input
                  label="Title"
                  value={formData.title}
                  onChange={updateFormData}
                  name="title"
                  fluid={true}
                  placeholder="Title"
                />
                <Form.TextArea
                  fluid={true}
                  label="Description"
                  value={formData.description}
                  name="description"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={updateFormData as any}
                  placeholder="Description"
                />
                <Segment>
                  <DatePicker
                    defaultValue={formData.date}
                    onDateChange={handleChangeDate}
                  />
                  <span style={{ marginLeft: '20px' }}>
                    <SelectType
                      defaultValue={formData.type}
                      placeholder="Select comment type"
                      data={selectTypesData}
                      onChange={handleChangeType}
                    />
                  </span>
                </Segment>
                <Button primary size="large" onClick={update}>
                  Update
                </Button>
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column width={6}>
            <Segment>
              <FileUpload image={formData.image} onUpload={handleOnUpload} />
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    </MainLayout>
  );
}

export default {
  component: Comment,
  exact: false,
  public: false,
  path: '/comment/:commentId',
};
