/* eslint-disable @typescript-eslint/no-explicit-any */
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { RegistrationData } from 'src/interfaces/authorization';
import { Comment, TeamMember, FilterItem } from '../interfaces/api';

export const checkAuth = (onSignOut: () => void) => {
  firebase.auth().onAuthStateChanged((user) => {
    if (!user) {
      onSignOut();
    }
  });
};

export const signIn = async (email: string, password: string) => {
  return await firebase.auth().signInWithEmailAndPassword(email, password);
};

export const signUp = async (email: string, password: string) => {
  return await firebase.auth().createUserWithEmailAndPassword(email, password);
};

export const signOut = async () => {
  return await firebase.auth().signOut();
};

export const getProfile = async (email: string) => {
  return await firebase.firestore().collection('users').doc(email).get();
};

export const getUser = () => {
  return firebase.auth().currentUser;
};

export const createUserInfo = async (data: RegistrationData) => {
  const user = getUser();

  const updatedData = { ...data, uuid: user.uid };

  return await firebase.firestore().collection('users').add(updatedData);
};

export const getPages = async () => {
  return await firebase.firestore().collection('pages').get();
};
export const getPage = async (pageId: string) => {
  return await firebase.firestore().collection('pages').doc(pageId).get();
};

export const updatePage = async (id: string, data: any) => {
  return await firebase.firestore().collection('pages').doc(id).update(data);
};

export const getComments = async () => {
  return await firebase.firestore().collection('comments').get();
};

export const getComment = async (commentId: string) => {
  return await firebase.firestore().collection('comments').doc(commentId).get();
};

export const createComment = async (comment: Comment) => {
  return await firebase.firestore().collection('comments').add(comment);
};

export const updateComment = async (id: string, data: any) => {
  return await firebase.firestore().collection('comments').doc(id).update(data);
};

export const getSettings = async () => {
  return await firebase.firestore().collection('settings').get();
};

export const getSetting = async (settingId: string) => {
  return await firebase.firestore().collection('settings').doc(settingId).get();
};

export const updateSetting = async (id: string, data: any) => {
  return await firebase.firestore().collection('settings').doc(id).update(data);
};

export const getTeamMembers = async () => {
  return await firebase.firestore().collection('team').get();
};

export const getTeamMember = async (memberId: string) => {
  return await firebase.firestore().collection('team').doc(memberId).get();
};

export const createTeamMember = async (member: TeamMember) => {
  return await firebase.firestore().collection('team').add(member);
};

export const updateTeamMember = async (id: string, data: TeamMember) => {
  return await firebase.firestore().collection('team').doc(id).update(data);
};

export const deleteTeamMember = async (id: string) => {
  return await firebase.firestore().collection('team').doc(id).delete();
};

export const getFilterItems = async () => {
  return await firebase.firestore().collection('filter').get();
};

export const getFilterItem = async (itemId: string) => {
  return await firebase.firestore().collection('filter').doc(itemId).get();
};

export const createFilterItem = async (filterItem: FilterItem) => {
  return await firebase.firestore().collection('filter').add(filterItem);
};

export const updateFilterItem = async (id: string, data: FilterItem) => {
  return await firebase.firestore().collection('filter').doc(id).update(data);
};

export const deleteFilterItem = async (id: string) => {
  return await firebase.firestore().collection('filter').doc(id).delete();
};
