import { useState, useEffect } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import * as api from 'src/services/api';
import Storage from 'src/services/storage';

// interface
import { RegistrationData } from 'src/interfaces/authorization';

export function useAuthorization(defaultValue: boolean) {
  const [profile, setProfile] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(defaultValue);

  useEffect(() => {
    api.checkAuth(() => {
      console.log('LOG ::::::> 111 <::::::',111)
      setIsLoggedIn(false);
      Storage.remove('isLoggedIn');
    });
  }, []);

  async function signIn(email: string, password: string) {
    const user = await api.signIn(email, password);
    console.log('LOG ::::::> user <::::::',user)
    if (user) {
      setIsLoggedIn(true);
      Storage.set('isLoggedIn', true);
    }
  }

  async function signUp(email: string, password: string) {
    const result = await api.signUp(email, password);
    setProfile(result);
  }

  async function signOut() {
    setProfile(null);
    await api.signOut();
  }

  async function createUserInfo(data: RegistrationData) {
    const result = await api.createUserInfo(data);
    if (result) {
      setIsLoggedIn(true);
      Storage.set('isLoggedIn', true);
    }
  }

  async function signInWithProvider(p: string) {
    const providerName = `${p[0].toUpperCase() + p.slice(1)}`;
    // we need to do that casting or next line will fail
    const auth: { [key: string]: any } = firebase.auth;
    const provider = new auth[`${providerName}AuthProvider`]();

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        setProfile(result.user);
        if (result.additionalUserInfo.isNewUser) {
          setProfile(result.user);
          Storage.set('showUserInfoForm', true);
          return true;
        } else {
          setIsLoggedIn(true);
          Storage.set('isLoggedIn', true);

          return false;
        }
      })
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          alert(error.message);
          return false;
        }
      });
  }

  return {
    profile,
    isLoggedIn,
    setProfile,
    setIsLoggedIn,
    signIn,
    signUp,
    createUserInfo,
    signOut,
    signInWithProvider,
  };
}
