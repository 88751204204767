/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// hooks
import { useForm } from 'src/hooks/useForm';

// components
import {
  Button,
  Form,
  Grid,
  Header,
  InputOnChangeData,
  Segment,
  Accordion,
  Icon,
} from 'semantic-ui-react';

import MainLayout from 'src/layouts/MainLayout';
import { getTeamMember, updateTeamMember } from 'src/services/api';
import { FileUpload } from 'src/components';
import {
  TeamMember,
  Contacts,
  Social,
  AdditionalItem,
} from '../../interfaces/api';

type RouteParams = {
  memberId: string;
};

const TeamMember: React.FC = () => {
  const history = useHistory();
  const { memberId } = useParams<RouteParams>();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showContacts, setShowContacts] = useState<boolean>(false);
  const [showSocial, setShowSocial] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formData, updateFormData, _, updateForm] = useForm<TeamMember | null>(
    null
  );

  useEffect(() => {
    (async function () {
      const data = await getTeamMember(memberId);
      const formData = data.data() as TeamMember;
      updateForm({ ...formData });
    })();
  }, []);

  async function update() {
    await updateTeamMember(memberId, formData);
    history.goBack();
  }

  function handleOnUpload(url: string, fileName: string) {
    const newData = {
      ...formData,
      image: !url
        ? null
        : {
            url,
            fileName,
          },
    };
    updateForm(newData);
    updateTeamMember(memberId, newData);
  }

  const firstToUpperCase = (str: string): string =>
    str[0].toUpperCase() + str.slice(1);

  const handleContactsUpdate = (
    _: React.FormEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    const { name, value } = data;
    updateForm({
      ...formData,
      contacts: {
        ...formData.contacts,
        [name]: value,
      },
    });
  };

  const handleSocialUpdate = (
    _: React.FormEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    const { name, value } = data;
    updateForm({
      ...formData,
      social: {
        ...formData.social,
        [name]: value,
      },
    });
  };

  const renderContacts = useMemo(() => {
    return (
      <div style={{ marginBottom: '20px' }}>
        <Accordion styled style={{ width: '100%' }}>
          <Accordion.Title onClick={() => setShowContacts(!showContacts)}>
            <Icon name="dropdown" />
            Contacts
          </Accordion.Title>
          <Accordion.Content active={showContacts}>
            <Segment>
              {!!formData &&
                Object.keys(formData.contacts).map((field) => (
                  <Form.Input
                    key={`member_${field}`}
                    label={firstToUpperCase(field)}
                    value={formData.contacts[field as keyof Contacts]}
                    onChange={handleContactsUpdate}
                    name={field}
                    fluid={true}
                    placeholder={firstToUpperCase(field)}
                  />
                ))}
            </Segment>
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }, [formData, showContacts]);

  const renderSocial = useMemo(() => {
    return (
      <div style={{ marginBottom: '20px' }}>
        <Accordion styled style={{ width: '100%' }}>
          <Accordion.Title onClick={() => setShowSocial(!showSocial)}>
            <Icon name="dropdown" />
            Social
          </Accordion.Title>
          <Accordion.Content active={showSocial}>
            <Segment>
              {!!formData &&
                Object.keys(formData.social).map((field) => (
                  <Form.Input
                    key={`member_${field}`}
                    label={firstToUpperCase(field)}
                    value={formData.social[field as keyof Social]}
                    onChange={handleSocialUpdate}
                    name={field}
                    fluid={true}
                    placeholder={firstToUpperCase(field)}
                  />
                ))}
            </Segment>
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }, [formData, showSocial]);

  const updateAdditionalInfo = (
    e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.currentTarget;
    const additional_info = [...formData.additional_info];
    additional_info[index][name as keyof AdditionalItem] = value;
    updateForm({ ...formData, additional_info });
  };

  const createAdditionalInfo = () => {
    const additional_info = [...formData.additional_info];
    additional_info.push({
      title: '',
      description: '',
    });
    updateForm({ ...formData, additional_info });
  };

  const deleteAdditionalInfo = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
  ) => {
    const additional_info = formData.additional_info.filter(
      (_, i) => i !== data.value
    );
    updateForm({ ...formData, additional_info });
  };

  const renderAdditionalInfo = useMemo(() => {
    return (
      <div style={{ marginBottom: '20px' }}>
        {!!formData && (
          <Accordion styled style={{ width: '100%' }}>
            <Accordion.Title onClick={() => setShowInfo(!showInfo)}>
              <Icon name="dropdown" />
              Additional info
            </Accordion.Title>
            <Accordion.Content active={showInfo}>
              {!!formData.additional_info?.length &&
                formData.additional_info.map((item, index) => {
                  return (
                    <Segment key={`info_${index}`}>
                      <Form.Input
                        label="Title"
                        value={formData.additional_info[index].title}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                          updateAdditionalInfo(e, index)
                        }
                        name="title"
                        fluid={true}
                        placeholder="Title"
                      />
                      <Form.TextArea
                        fluid={true}
                        label="Description"
                        value={formData.additional_info[index].description}
                        name="description"
                        onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                          updateAdditionalInfo(e, index)
                        }
                        placeholder="Description"
                      />
                      <Button
                        color="red"
                        size="large"
                        value={index}
                        onClick={deleteAdditionalInfo}
                      >
                        Delete
                      </Button>
                    </Segment>
                  );
                })}
              <Button primary size="large" onClick={createAdditionalInfo}>
                Create
              </Button>
            </Accordion.Content>
          </Accordion>
        )}
      </div>
    );
  }, [formData, showInfo]);

  return (
    <MainLayout>
      <Segment>
        <Header as="h2">Team</Header>
        {!!formData && (
          <Grid>
            <Grid.Column width={10}>
              <Segment>
                <Header as="h2">Member of the team</Header>
                <Form size="large">
                  <Form.Input
                    label="Name"
                    value={formData.name}
                    onChange={updateFormData}
                    name="name"
                    fluid={true}
                    placeholder="Name"
                  />
                  <Form.Input
                    label="Position"
                    value={formData.position}
                    onChange={updateFormData}
                    name="position"
                    fluid={true}
                    placeholder="Position"
                  />
                  <Form.TextArea
                    fluid={true}
                    label="Description"
                    value={formData.description}
                    name="description"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={updateFormData as any}
                    placeholder="Description"
                  />
                  {renderContacts}
                  {renderSocial}
                  {renderAdditionalInfo}
                  <Button primary size="large" onClick={update}>
                    Update
                  </Button>
                </Form>
              </Segment>
            </Grid.Column>
            <Grid.Column width={6}>
              <Segment>
                <FileUpload image={formData.image} onUpload={handleOnUpload} />
              </Segment>
            </Grid.Column>
          </Grid>
        )}
      </Segment>
    </MainLayout>
  );
};

export default {
  component: TeamMember,
  exact: false,
  public: false,
  path: '/team_member/:memberId',
};
