import * as firebase from 'firebase/app';

export function initFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyDZUCe79fnYTr_qFC581q1qgAo5IPqoeXQ",
    authDomain: "trastlarry.firebaseapp.com",
    databaseURL: "https://trastlarry.firebaseio.com",
    projectId: "trastlarry",
    storageBucket: "trastlarry.appspot.com",
    messagingSenderId: "894036221898",
    appId: "1:894036221898:web:0eb5e67d55007800ad7933",
    measurementId: "G-DW44LK3XHT"
  };

  firebase.initializeApp(firebaseConfig);
}
