import * as React from 'react';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// components
import { Button, Table } from 'semantic-ui-react';
import MainLayout from 'src/layouts/MainLayout';

import { getComments, createComment } from 'src/services/api';
import { Comment } from '../../interfaces/api';

const EMPTY_COMMENT: Comment = {
  title: '',
  description: '',
  date: '',
  image: '',
  type: '',
};

function Comments() {
  const history = useHistory();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    (async function () {
      const data = await getComments();
      if (data && data.docs && data.docs.length) {
        const result = data.docs.map((item) => {
          return { ...item.data(), id: item.id };
        });
        setComments(result);
      }
    })();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function editComment(e: SyntheticEvent, data: any) {
    history.push('/comment/' + data.comment.id);
  }

  const createNewComment = async () => {
    const { id } = await createComment(EMPTY_COMMENT);
    if (id) {
      history.push(`/comment/${id}`);
    }
  };

  return (
    <MainLayout isLoading={!comments.length}>
      <Table fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Comment</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>type</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {!!comments.length &&
            comments.map((comment, i) => {
              return (
                <Table.Row key={comment.id}>
                  <Table.Cell>{i + 1}</Table.Cell>
                  <Table.Cell>{comment.title}</Table.Cell>
                  <Table.Cell>{comment.description}</Table.Cell>
                  <Table.Cell>{comment.type}</Table.Cell>
                  <Table.Cell>
                    <Button comment={comment} onClick={editComment}>
                      Edit
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      <div style={{ marginTop: '20px' }}>
        <Button primary size="large" onClick={createNewComment}>
          Create
        </Button>
      </div>
    </MainLayout>
  );
}

export default {
  component: Comments,
  exact: false,
  public: false,
  path: '/comments',
};
