import * as React from 'react';
import { RouteBuilder } from 'src/helpers/routeBuilder';

import { routes } from 'src/pages/routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { initFirebase } from 'src/services/firebase';
import Storage from 'src/services/storage';
import 'semantic-ui-css/semantic.min.css';
import 'src/styles/main.css';

// contexts
import { AuthorizationContext } from 'src/contexts/authorization';

// hooks
import { useAuthorization } from 'src/hooks/useAuthorization';

initFirebase();

export default function App() {
  const authData = useAuthorization(Storage.get('isLoggedIn'));

  return (
    <AuthorizationContext.Provider value={authData}>
      <Router>
        <RouteBuilder routes={routes} isSignIn={authData.isLoggedIn} />
      </Router>
    </AuthorizationContext.Provider>
  );
}
