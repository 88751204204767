/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

interface DatePickerProps {
  defaultValue?: string;
  onDateChange: (value: string) => void;
}

function DatePicker({ defaultValue, onDateChange }: DatePickerProps) {
  const onChange = (_: any, data: any) => {
    onDateChange(data.value);
  };

  return (
    <SemanticDatepicker
      value={defaultValue ? new Date(defaultValue) : undefined}
      onChange={onChange}
    />
  );
}

export default DatePicker;
