import * as React from 'react';
import { Route, Switch, Redirect, RouteProps } from 'react-router-dom';

interface RoutesI extends RouteProps {
  public: boolean;
}

interface PropsI {
  routes: RoutesI[];
  isSignIn: boolean;
}

export const RouteBuilder = (props: PropsI) => {
  const { routes, isSignIn } = props;

  const renderRoute = (route: RoutesI, type: string) => {
    return <Route key={`${type}${route.path}`} {...route} />;
  };

  const renderPublicRoutes = () => {
    return (
      <Switch>
        {routes.map((route) => route.public && renderRoute(route, 'public'))}
        <Route exact path="/">
          <Redirect to={routes[0].path as string} />
        </Route>
      </Switch>
    );
  };

  const renderPrivateRoutes = () => {
    return (
      <Switch>
        {routes.map((route) => !route.public && renderRoute(route, 'private'))}
        <Route exact path="/">
          <Redirect to={routes[1].path as string} />
        </Route>
      </Switch>
    );
  };

  return isSignIn ? renderPrivateRoutes() : renderPublicRoutes();
};
