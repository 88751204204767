import * as React from 'react';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// components
import { Button, ButtonProps, Table } from 'semantic-ui-react';
import MainLayout from 'src/layouts/MainLayout';

import {
  getFilterItems,
  createFilterItem,
  deleteFilterItem,
} from 'src/services/api';
import { FilterItem } from 'src/interfaces/api';

const EMPTY_FILTER: FilterItem = {
  key: '',
  title: '',
  multiple: true,
  isFilled: true,
  values: [{ label: '', value: '' }],
};

const Filters: React.FC = () => {
  const history = useHistory();
  const [filters, setFilters] = useState<FilterItem[]>([]);

  useEffect(() => {
    (async function () {
      const data = await getFilterItems();
      if (data && data.docs && data.docs.length) {
        setFilters(
          data.docs.map(
            (item) => ({ ...item.data(), id: item.id } as FilterItem)
          )
        );
      }
    })();
  }, []);

  const editSetting = (_: SyntheticEvent, data: ButtonProps) => {
    history.push(`/filter/${data.filter.id}`);
  };

  const createNewFilter = async () => {
    const { id } = await createFilterItem(EMPTY_FILTER);
    if (id) {
      history.push(`/filter/${id}`);
    }
  };

  const removeFilter = async (_: SyntheticEvent, data: ButtonProps) => {
    await deleteFilterItem(data.filter.id);
    window.location.reload();
  };

  return (
    <MainLayout isLoading={!filters.length}>
      <Table fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {!!filters.length &&
            filters.map((filter) => {
              return (
                <Table.Row key={filter.key}>
                  <Table.Cell>{filter.key}</Table.Cell>
                  <Table.Cell>{filter.title}</Table.Cell>
                  <Table.Cell>
                    <Button filter={filter} onClick={editSetting}>
                      Edit
                    </Button>
                    <Button color="red" filter={filter} onClick={removeFilter}>
                      Remove
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      <div style={{ marginTop: '20px' }}>
        <Button primary size="large" onClick={createNewFilter}>
          Create
        </Button>
      </div>
    </MainLayout>
  );
};

export default {
  component: Filters,
  exact: false,
  public: false,
  path: '/filters',
};
