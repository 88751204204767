// @flow
import * as React from 'react';
import { memo, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

// components
import {
  Dimmer,
  Icon,
  Loader,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react';
import logo from 'src/assets/icons/logo.svg';

import { signOut } from '../services/api';

interface MainLayoutProps {
  isLoading?: boolean;
  children: ReactNode | ReactNode[];
}

const MainLayout = (props: MainLayoutProps) => {
  const history = useHistory();
  const [visible, setVisible] = React.useState(true);

  async function handleLogout() {
    await signOut();
    history.push('/');
  }

  return (
    <div className="main-content">
      <Sidebar as={Menu} vertical visible className="_sideMenu">
        {/* <Menu.Item as='a'>
          <Icon name='tachometer alternate' />
          Dashboard
        </Menu.Item> */}
        <Menu.Item as="a" link href="/pages">
          <Icon name="file" />
          Pages
        </Menu.Item>
        <Menu.Item as="a" link href="/comments">
          <Icon name="comment" />
          Comments
        </Menu.Item>
        <Menu.Item as="a" link href="/filters">
          <Icon name="filter" />
          Filter
        </Menu.Item>
        <Menu.Item as="a" link href="/team">
          <Icon name="users" />
          Team
        </Menu.Item>
        <Menu.Item as="a" link href="/settings">
          <Icon name="settings" />
          Settings
        </Menu.Item>
      </Sidebar>
      <Menu className="top_menu_logo">
        <img src={logo} alt="" style={{ width: 184, height: 36 }} />
      </Menu>
      <Menu className="top_menu">
        <Menu.Menu position="right">
          <Menu.Item name="logout" onClick={handleLogout} />
        </Menu.Menu>
      </Menu>
      <Sidebar.Pusher>
        <Dimmer active={props.isLoading} inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>
        <Segment basic>{props.children}</Segment>
      </Sidebar.Pusher>
    </div>
  );
};

MainLayout.defaultProps = {};

export default memo(MainLayout);
