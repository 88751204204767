/* eslint-disable @typescript-eslint/no-use-before-define */
// @flow
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { memo } from 'react';
import { FileDrop } from 'react-file-drop';

import * as firebase from 'firebase';
import { Button } from 'semantic-ui-react';

interface FileUploadProps {
  index?: string;
  multiple?: boolean;
  images?: string[];
  image?: {
    url: string;
    fileName: string;
  };
  onUpload: (url: string, fileName?: string) => void;
}

const FileUpload = (props: FileUploadProps) => {
  const [files, setFiles] = useState([]);
  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!files.length && props.image) {
      setFiles([props.image.url]);
    }
  }, [props.image]);

  const generateRandomFileName = (size: number): string => {
    const ALPHABET_SIZE = 27;
    let result = '';
    for (let i = 0; i < size; i++) {
      const random = Math.floor(Math.random() * ALPHABET_SIZE);
      result += String.fromCharCode(97 + random);
    }
    return result;
  };

  function uploadFile(file: File) {
    const fileName = generateRandomFileName(20);

    removeFile(() => {
      firebase
        .storage()
        .ref()
        .child(`images/${fileName}`)
        .put(file)
        .then(() => {
          firebase
            .storage()
            .ref()
            .child(`images/${fileName}`)
            .getDownloadURL()
            .then((url) => {
              props.onUpload(url, fileName);
            });
        });
    });
  }

  function removeFile(callBack?: () => void) {
    if (!props.image) {
      callBack();
    } else {
      firebase
        .storage()
        .ref()
        .child(`images/${props.image.fileName}`)
        .delete()
        .then(() => {
          if (callBack) {
            callBack();
          } else {
            props.onUpload('');
          }
        });
    }
  }

  function onDrop(f: FileList) {
    setFiles([f[0]]);
    uploadFile(f[0]);
  }

  function getUrl(file: File | string) {
    return typeof file === 'string' ? file : URL.createObjectURL(file);
  }

  function handleFiles(event: ChangeEvent<HTMLInputElement>) {
    setFiles([event.target.files[0]]);
    uploadFile(event.target.files[0]);
  }

  function removeImage() {
    if (props.image.url) {
      removeFile();
    }
    setFiles([]);
  }

  function openUpload() {
    if (fileInput?.current) {
      fileInput.current.click();
    }
  }

  const _renderFiles = useMemo(() => {
    return (
      !!files.length &&
      files.map((file, i) => {
        return (
          <div key={`file_${i}`} className="upload_images">
            <div
              style={{
                background: `url(${getUrl(file)}) no-repeat center/cover`,
              }}
              className="img"
            />
            <div style={{ marginTop: 20 }}>
              <Button content="Change" primary onClick={openUpload} />
              <Button content="Remove" color="red" onClick={removeImage} />
            </div>
          </div>
        );
      })
    );
  }, [files]);

  return (
    <div>
      <FileDrop
        className={!!files.length ? 'hide' : 'file-drop file-drop-target'}
        onDrop={onDrop}
      >
        Drop some files here!
        <label
          className="upload_text"
          htmlFor={`myfile_${props.index || 'default'}`}
        >
          or click for uplaod!
          <input
            accept="image/*"
            id={`myfile_${props.index || 'default'}`}
            multiple
            type="file"
            style={{ display: 'none' }}
            onChange={handleFiles}
            ref={fileInput}
          />
        </label>
      </FileDrop>
      {_renderFiles}
    </div>
  );
};

FileUpload.defaultProps = {};

export default memo(FileUpload);
