// pages
import Login from './Login';
import Pages from './Pages';
import Page from './Page';
import Comments from './Comments';
import Comment from './Comment';
import Settings from './Settings';
import Setting from './Setting';
import Team from './Team';
import TeamMember from './TeamMember';
import Filters from './Filters';
import Filter from './Filter';

export const routes = [
  Login,
  Pages,
  Page,
  Comments,
  Comment,
  Settings,
  Setting,
  Team,
  TeamMember,
  Filters,
  Filter,
];
