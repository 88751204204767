export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isNull(value: unknown): value is null {
  return value === null;
}

export function isUndefined(value: unknown): value is undefined {
  return typeof value === 'undefined';
}

export function isArray(value: unknown): value is Array<unknown> {
  return Array.isArray(value);
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isFunction(value: unknown): value is Function {
  return typeof value === 'function';
}

export function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && !isArray(value) && !isNull(value);
}

export function cloneObject<T>(value: T): T {
  return { ...value };
}

export function cloneArray(value: unknown[]): unknown[] {
  return value.slice();
}

export function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj;
}

export const getLocalization = (lang: string) => {
  const language = navigator.language.split('-');
  const value = lang || language[0];

  if (value === 'ru') {
    return 'ru';
  } else {
    return 'en';
  }
};

export const getDataByPath = (path: string, data: Record<string, any>): string => {
  return path.split('.').reduce((prev, next: string | number) => prev && prev[next], data);
};

export const isLocalStorageEnabled = () => {
  try {
    const mod = '__storage_test__';
    window.localStorage.setItem(mod, mod);
    window.localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
};
