import * as React from 'react';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// components
import { Button, Table } from 'semantic-ui-react';
import MainLayout from 'src/layouts/MainLayout';

import { getSettings } from 'src/services/api';

type Setting = {
  id: string;
};

const Settings: React.FC = () => {
  const history = useHistory();
  const [settings, setSettings] = useState<Setting[]>([]);

  useEffect(() => {
    (async function () {
      const data = await getSettings();
      if (data && data.docs && data.docs.length) {
        setSettings(data.docs.map((item) => ({ id: item.id })));
      }
    })();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editSetting = (_: SyntheticEvent, data: any) => {
    history.push('/setting/' + data.setting.id);
  };

  return (
    <MainLayout isLoading={!settings.length}>
      <Table fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Setting</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {!!settings.length &&
            settings.map((setting) => {
              return (
                <Table.Row key={setting.id}>
                  <Table.Cell>{setting.id}</Table.Cell>
                  <Table.Cell>
                    <Button setting={setting} onClick={editSetting}>
                      Edit
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </MainLayout>
  );
};

export default {
  component: Settings,
  exact: false,
  public: false,
  path: '/settings',
};
