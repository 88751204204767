/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// hooks
import { useForm } from 'src/hooks/useForm';

// components
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Accordion,
  Icon,
  Radio,
  CheckboxProps,
  InputOnChangeData,
} from 'semantic-ui-react';

import MainLayout from 'src/layouts/MainLayout';
import { getFilterItem, updateFilterItem } from 'src/services/api';
import { FilterItem } from '../../interfaces/api';

type RouteParams = {
  itemId: string;
};

const FilterItem: React.FC = () => {
  const history = useHistory();
  const { itemId } = useParams<RouteParams>();
  const [showInfo, setShowInfo] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formData, updateFormData, _, updateForm] = useForm<FilterItem | null>(
    null
  );

  useEffect(() => {
    (async function () {
      const data = await getFilterItem(itemId);
      const formData = data.data() as FilterItem;
      updateForm({ ...formData });
    })();
  }, []);

  async function update() {
    await updateFilterItem(itemId, formData);
    history.goBack();
  }

  const updateValuesItem = (
    e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.currentTarget;
    const values = [...formData.values];
    values[index].label = value;
    values[index].value = value;
    updateForm({ ...formData, values });
  };

  const createValuesItem = () => {
    const values = [...formData.values];
    values.push({
      label: '',
      value: '',
    });
    updateForm({ ...formData, values });
  };

  const deleteValuesItem = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
  ) => {
    const values = formData.values.filter((_, i) => i !== data.value);
    updateForm({ ...formData, values });
  };

  const handleRadioButtons = (
    _: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    const { value, name } = data;
    updateForm({ ...formData, [name]: value === name });
  };

  const transformTitleToKey = (title: string) =>
    title.toLowerCase().replace(' ', '_');

  const handleTitleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    const { value } = data;
    updateForm({ ...formData, title: value, key: transformTitleToKey(value) });
  };

  const renderValues = useMemo(() => {
    return (
      <div style={{ marginBottom: '20px' }}>
        {!!formData && (
          <>
            <Header as="h4">Number of options</Header>
            <Form.Field>
              <Radio
                label="Single"
                name="multiple"
                value="single"
                checked={!formData.multiple}
                onChange={handleRadioButtons}
              />
              <Radio
                style={{ marginLeft: '40px' }}
                label="Multiple"
                name="multiple"
                value="multiple"
                checked={formData.multiple}
                onChange={handleRadioButtons}
              />
            </Form.Field>
            <Accordion styled style={{ width: '100%' }}>
              <Accordion.Title onClick={() => setShowInfo(!showInfo)}>
                <Icon name="dropdown" />
                Values
              </Accordion.Title>
              <Accordion.Content active={showInfo}>
                {!!formData.values?.length &&
                  formData.values.map((item, index) => {
                    return (
                      <Segment key={`info_${index}`}>
                        <Form.Input
                          label="Value"
                          value={item.value}
                          onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            updateValuesItem(e, index)
                          }
                          name="value"
                          fluid={true}
                          placeholder="Value"
                        />
                        <Button
                          color="red"
                          size="large"
                          value={index}
                          onClick={deleteValuesItem}
                        >
                          Delete
                        </Button>
                      </Segment>
                    );
                  })}
                <Button primary size="large" onClick={createValuesItem}>
                  Create
                </Button>
              </Accordion.Content>
            </Accordion>
          </>
        )}
      </div>
    );
  }, [formData, showInfo]);

  const renderPlaceholder = useMemo(() => {
    return (
      <div style={{ marginBottom: '20px' }}>
        {!!formData && (
          <Form.Input
            label="Placeholder"
            value={formData.placeholder}
            onChange={updateFormData}
            name="placeholder"
            fluid={true}
            placeholder="Placeholder"
          />
        )}
      </div>
    );
  }, [formData]);

  return (
    <MainLayout>
      <Segment>
        <Header as="h2">Filter</Header>
        {!!formData && (
          <Grid>
            <Grid.Column width={10}>
              <Segment>
                <Form size="large">
                  <Form.Input
                    label="Title"
                    value={formData.title}
                    onChange={handleTitleChange}
                    name="title"
                    fluid={true}
                    placeholder="Title"
                  />
                  <Header as="h4">Field value to fill</Header>
                  <Form.Field style={{ marginBottom: '40px' }}>
                    <Radio
                      label="Yes"
                      name="isFilled"
                      value="isFilled"
                      checked={formData.isFilled}
                      onChange={handleRadioButtons}
                    />
                    <Radio
                      style={{ marginLeft: '40px' }}
                      label="No"
                      name="isFilled"
                      value="notFilled"
                      checked={!formData.isFilled}
                      onChange={handleRadioButtons}
                    />
                  </Form.Field>
                  {formData.isFilled ? renderPlaceholder : renderValues}
                  <Button primary size="large" onClick={update}>
                    Update
                  </Button>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid>
        )}
      </Segment>
    </MainLayout>
  );
};

export default {
  component: FilterItem,
  exact: false,
  public: false,
  path: '/filter/:itemId',
};
