import { ChangeEvent, useState } from 'react';

type UseForm<T> = [
  T,
  (e: ChangeEvent<HTMLInputElement>) => void,
  () => void,
  (data: T) => void
];

export function useForm<T>(initialState: T): UseForm<T> {
  const [formData, setFormData] = useState<T>(initialState);

  const updateFormData = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormData({ ...formData, [name]: value });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateForm = (data: Record<string, any>) => {
    setFormData({ ...formData, ...data });
  };

  // const updateFormByName = (data: unknown, name: string) => {
  //   setFormData({ ...formData, [name]: data });
  // };

  const clearFrom = () => {
    setFormData(initialState);
  };

  return [formData, updateFormData, clearFrom, updateForm];
}
