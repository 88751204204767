/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// hooks
import { useForm } from 'src/hooks/useForm';

// components
import {
  Accordion,
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import LinkInput from '../../components/LinkInput';

// theme
import MainLayout from 'src/layouts/MainLayout';
import { getPage, updatePage } from 'src/services/api';
import { FileUpload } from 'src/components';
import {
  Img,
  AdditionalInfo,
  UpdateInfoData,
  AdditionalInfoWithoutImg,
  EMPTY_STRING,
  InfoWithKey,
  Page,
  RouteParams,
  UpdateImagesFunc,
  FormData,
  UploadHandler,
} from './types';

const NEIGHBORHOODS = 'neighborhoods';
const NEIGHBORHOODS_IMG_SIZE = 4;
const MAX_IMG_COUNT = 6;

function Page() {
  const history = useHistory();
  const params = useParams<RouteParams>();
  const [page, setPage] = useState<Page | null>(null);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showLinkBtn, setShowLinkBtn] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formData, updateFormData, _, updateForm] = useForm<FormData>({
    title: '',
    description: '',
  });

  useEffect(() => {
    (async function () {
      const data = await getPage(params.pageId);
      setPage({ ...data.data(), id: params.pageId });
      const formData = data.data() as FormData;
      updateForm({
        ...formData,
        title: formData.title || '',
        description: formData.description || '',
      });
    })();
  }, []);

  const checkEmptyImagesInForm = () => {
    let additional_info = [...formData.additional_info];
    additional_info = additional_info.map((inf) =>
      inf.images_1
        ? {
            ...inf,
            images_1: inf.images_1.filter((img) => !!img && !!img.url),
            images_2: inf.images_2.filter((img) => !!img && !!img.url),
          }
        : inf
    );
    return { ...formData, additional_info };
  };

  async function update() {
    const checkedForm = formData.additional_info[0].images_1
      ? checkEmptyImagesInForm()
      : formData;
    await updatePage(params.pageId, checkedForm);
    history.goBack();
  }

  function updateAdditionalInfo(
    e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) {
    const { name, value } = e.currentTarget;
    const additional_info = [...formData.additional_info];
    additional_info[index][name as keyof AdditionalInfoWithoutImg] = value;
    updateForm({ ...formData, additional_info });
  }

  function updateAdditionalImages(data: Img, index: number) {
    const { url, fileName } = data;
    const additional_info = [...formData.additional_info];
    additional_info[index].image = {
      url,
      fileName,
    };
    updateForm({ ...formData, additional_info });
  }

  function createAdditionalInfo() {
    const additional_info = [...formData.additional_info];
    const newAdditionalInfo = {
      title: EMPTY_STRING,
      description: EMPTY_STRING,
    } as AdditionalInfo;
    if ('image' in formData.additional_info[0]) {
      newAdditionalInfo.image = EMPTY_STRING;
    }
    if ('images' in formData.additional_info[0]) {
      newAdditionalInfo.images = [];
    }
    if ('images_1' in formData.additional_info[0]) {
      newAdditionalInfo.images_1 = [];
    }
    if ('images_2' in formData.additional_info[0]) {
      newAdditionalInfo.images_2 = [];
    }
    additional_info.push(newAdditionalInfo);
    updateForm({ ...formData, additional_info });
  }

  function deleteAdditionalInfo(
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
  ) {
    const additional_info = formData.additional_info.filter(
      (_, i) => i !== data.value
    );

    updateForm({ ...formData, additional_info });
  }

  function handleOnUpload(url: string, fileName: string) {
    const newData = {
      ...formData,
      image: !url
        ? null
        : {
            url,
            fileName,
          },
    };
    updateForm(newData);
    updatePage(params.pageId, newData);
  }

  const handleDescriptionFocus = () => {
    setShowLinkBtn(true);
  };

  const getEmptyImagesArray = (size: number): undefined[] =>
    Array(size).fill(undefined);

  const EMPTY_ARRAY_4 = getEmptyImagesArray(NEIGHBORHOODS_IMG_SIZE);
  const MANY_EMPTY_ARRAY = getEmptyImagesArray(1);

  const changeAdditionalInfoItem = (
    additionalInfo: AdditionalInfo,
    idx: number,
    data: Img
  ): AdditionalInfo => {
    const { url, fileName } = data;
    const images =
      !!additionalInfo.images && !!additionalInfo.images.length
        ? additionalInfo.images
        : EMPTY_ARRAY_4;

    return {
      ...additionalInfo,
      images: images.map((img, i) => (i === idx ? { fileName, url } : img)),
    };
  };

  const changeFirstImages = (
    additionalInfo: AdditionalInfo,
    idx: number,
    data: Img
  ): AdditionalInfo => {
    const { url, fileName } = data;
    const images =
      !!additionalInfo.images_1 && !!additionalInfo.images_1.length
        ? additionalInfo.images_1
        : MANY_EMPTY_ARRAY;

    return {
      ...additionalInfo,
      images_1: images.map((img, i) => (i === idx ? { fileName, url } : img)),
    };
  };

  const changeSecondImages = (
    additionalInfo: AdditionalInfo,
    idx: number,
    data: Img
  ): AdditionalInfo => {
    const { url, fileName } = data;
    const images =
      !!additionalInfo.images_2 && !!additionalInfo.images_2.length
        ? additionalInfo.images_2
        : MANY_EMPTY_ARRAY;

    return {
      ...additionalInfo,
      images_2: images.map((img, i) => (i === idx ? { fileName, url } : img)),
    };
  };

  const getUploaderHandler = (imgChanger: UpdateImagesFunc) => (
    data: Img,
    infoIdx: number,
    imgIdx: number
  ) => {
    const additionalInfo = [...formData.additional_info];
    additionalInfo.splice(
      infoIdx,
      1,
      imgChanger(additionalInfo[infoIdx], imgIdx, data)
    );
    const newForm = { ...formData, additional_info: additionalInfo };
    updateForm(newForm);
  };

  const handleOnAdditionalUpload = getUploaderHandler(changeAdditionalInfoItem);
  const handleFirstUpload = getUploaderHandler(changeFirstImages);
  const handleSecondUpload = getUploaderHandler(changeSecondImages);

  const renderFileUploader = (
    infoIdx: number,
    imgIdx: number,
    handler: UploadHandler
  ) => {
    return (
      <Grid.Column key={`upload_${infoIdx}_${imgIdx}`}>
        <FileUpload
          index={`${infoIdx}_${imgIdx}`}
          image={formData.additional_info[infoIdx].images[imgIdx] || undefined}
          onUpload={(url, fileName) =>
            handler({ url, fileName }, infoIdx, imgIdx)
          }
        />
      </Grid.Column>
    );
  };

  const renderNeighborhoodsInfo = (index: number) => {
    return (
      <>
        <Form.TextArea
          fluid={true}
          label="Lifestyle"
          value={formData.additional_info[index].lifestyle}
          name="lifestyle"
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
            updateAdditionalInfo(e, index)
          }
          placeholder="Lifestyle"
        />
        <Form.TextArea
          fluid={true}
          label="Community amenities"
          value={formData.additional_info[index].amenities}
          name="amenities"
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
            updateAdditionalInfo(e, index)
          }
          placeholder="Community amenities"
        />
        <Form.TextArea
          fluid={true}
          label="Real estate market overview"
          value={formData.additional_info[index].overview}
          name="overview"
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
            updateAdditionalInfo(e, index)
          }
          placeholder="Real estate market overview"
        />

        <Grid style={{ marginBottom: '20px' }}>
          <Grid.Row columns={4}>
            {EMPTY_ARRAY_4.map((_, i) =>
              renderFileUploader(index, i, handleOnAdditionalUpload)
            )}
          </Grid.Row>
        </Grid>
      </>
    );
  };

  const addNewImage = (
    field: 'images' | 'images_1' | 'images_2',
    index: number
  ) => {
    const additionalInfo = [...formData.additional_info];
    if (additionalInfo[index][field].length + 1 < MAX_IMG_COUNT) {
      additionalInfo[index][field].push('');
    }
    updateForm({ ...formData, additional_info: additionalInfo });
  };

  const renderManyImages = (index: number) => {
    return (
      <Grid style={{ marginTop: '20px' }}>
        <Header as="h5">First images</Header>
        <Grid.Row columns={4}>
          {formData.additional_info[index].images_1.map((_, i) => (
            <Grid.Column key={`upload_${index}_${i}`}>
              <FileUpload
                index={`${index}_${i}`}
                image={formData.additional_info[index].images_1[i] || undefined}
                onUpload={(url, fileName) =>
                  handleFirstUpload({ url, fileName }, index, i)
                }
              />
            </Grid.Column>
          ))}
          <Button size="large" onClick={() => addNewImage('images_1', index)}>
            <Icon name="plus" style={{ margin: 0 }} />
          </Button>
        </Grid.Row>
        <Header as="h5">Second images</Header>
        <Grid.Row columns={4}>
          {formData.additional_info[index].images_2.map((_, i) => (
            <Grid.Column key={`upload_${index}_${i + MAX_IMG_COUNT}`}>
              <FileUpload
                index={`${index}_${i + MAX_IMG_COUNT}`}
                image={formData.additional_info[index].images_2[i] || undefined}
                onUpload={(url, fileName) =>
                  handleSecondUpload({ url, fileName }, index, i)
                }
              />
            </Grid.Column>
          ))}
          <Button size="large" onClick={() => addNewImage('images_2', index)}>
            <Icon name="plus" style={{ margin: 0 }} />
          </Button>
        </Grid.Row>
      </Grid>
    );
  };

  const renderUploaderItem = (index: number) => {
    return (
      <Grid.Column key={`upload_${index}`} style={{ marginBottom: '20px' }}>
        <Segment>
          <FileUpload
            index={`${index}`}
            image={formData.additional_info[index].image || undefined}
            onUpload={(url, fileName) =>
              updateAdditionalImages({ url, fileName }, index)
            }
          />
        </Segment>
      </Grid.Column>
    );
  };

  const updateInfo = (
    data: UpdateInfoData,
    index: number,
    additionalInfoIdx: number
  ) => {
    const { field, value } = data;
    const additionalInfo = [...formData.additional_info];
    const newAdditionalInfo = additionalInfo.map((additInf, i) => {
      return i === additionalInfoIdx
        ? {
            ...additInf,
            info: additInf.info.map((inf, i) =>
              i === index ? { ...inf, [field]: value } : inf
            ),
          }
        : additInf;
    });
    updateForm({ ...formData, additional_info: newAdditionalInfo });
  };

  const updateInfoWithKey = (
    e: React.FormEvent<HTMLInputElement>,
    index: number,
    additionalInfoIdx: number
  ) => {
    const { name, value } = e.currentTarget;
    updateInfo({ field: name, value }, index, additionalInfoIdx);
  };

  const updateImageWithKey = (
    data: Img,
    index: number,
    additionalInfoIdx: number
  ) => {
    updateInfo({ field: 'image', value: data }, index, additionalInfoIdx);
  };

  const renderInfoWithKey = (
    info: InfoWithKey,
    index: number,
    additionalInfoIdx: number
  ) => {
    return (
      <div style={{ marginBottom: '20px' }} key={info.key}>
        <Accordion styled style={{ width: '100%' }}>
          <Accordion.Title>
            <Icon name="dropdown" />
            {info.key}
          </Accordion.Title>
          <Accordion.Content active>
            <Segment>
              <Form.Input
                label="Title"
                value={info.title}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  updateInfoWithKey(e, index, additionalInfoIdx)
                }
                name="title"
                fluid={true}
                placeholder="Title"
              />
            </Segment>
            <Segment>
              <FileUpload
                index={`${info.key}_${index}`}
                image={info.image || undefined}
                onUpload={(url, fileName) =>
                  updateImageWithKey(
                    { url, fileName },
                    index,
                    additionalInfoIdx
                  )
                }
              />
            </Segment>
          </Accordion.Content>
        </Accordion>
      </div>
    );
  };

  const handleChangeAdditionalInfoLink = (text: string, index: number) => {
    const newFormData = { ...formData };
    newFormData.additional_info[index].description = text;
    updateForm(newFormData);
  };

  function renderAdditionalInfo() {
    if (!page?.additional_info) {
      return null;
    }

    return (
      <div style={{ marginBottom: '20px' }}>
        <Accordion styled style={{ width: '100%' }}>
          <Accordion.Title onClick={() => setShowInfo(!showInfo)}>
            <Icon name="dropdown" />
            Additional info
          </Accordion.Title>
          <Accordion.Content active={showInfo}>
            {!!formData.additional_info?.length &&
              formData.additional_info.map((item, index) => {
                return (
                  <Segment key={`info_${index}`}>
                    {!!item.key && <Header as="h4">{item.key}</Header>}
                    <Form.Input
                      label="Title"
                      value={formData.additional_info[index].title}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        updateAdditionalInfo(e, index)
                      }
                      name="title"
                      fluid={true}
                      placeholder="Title"
                    />
                    <Form.TextArea
                      fluid={true}
                      label="Description"
                      value={formData.additional_info[index].description}
                      name="description"
                      onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                        updateAdditionalInfo(e, index)
                      }
                      placeholder="Description"
                    />
                    <LinkInput
                      index={index}
                      text={formData.additional_info[index].description}
                      onChange={handleChangeAdditionalInfoLink}
                    />
                    {params.pageId === NEIGHBORHOODS &&
                      renderNeighborhoodsInfo(index)}
                    {'image' in formData.additional_info[0] &&
                      renderUploaderItem(index)}
                    {'info' in formData.additional_info[index] &&
                      formData.additional_info[index].info.map((inf, i) =>
                        renderInfoWithKey(inf, i, index)
                      )}
                    {'images_1' in formData.additional_info[index] &&
                      'images_2' in formData.additional_info[index] &&
                      renderManyImages(index)}
                    {!formData.isNotExpand && index !== 0 && (
                      <Button
                        color="red"
                        size="large"
                        value={index}
                        onClick={deleteAdditionalInfo}
                      >
                        Delete
                      </Button>
                    )}
                  </Segment>
                );
              })}
            {!formData.isNotExpand && (
              <Button primary size="large" onClick={createAdditionalInfo}>
                Create
              </Button>
            )}
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }

  const handleChangeLink = (text: string) => {
    updateForm({ ...formData, description: text });
  };

  return (
    <MainLayout>
      <Segment>
        <Header as="h2">Main content</Header>
        <Grid>
          <Grid.Column width={10}>
            <Segment>
              <Header as="h2">{page?.id || ''} page</Header>
              <Form size="large">
                <Form.Input
                  label="Title"
                  value={formData.title}
                  onChange={updateFormData}
                  name="title"
                  fluid={true}
                  placeholder="Title"
                />
                <Form.TextArea
                  fluid={true}
                  label="Description"
                  value={formData.description}
                  name="description"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={updateFormData as any}
                  placeholder="Description"
                  onFocus={handleDescriptionFocus}
                />
                {showLinkBtn && (
                  <LinkInput
                    text={formData.description}
                    onChange={handleChangeLink}
                  />
                )}
                {renderAdditionalInfo()}
                <Button primary size="large" onClick={update}>
                  Update
                </Button>
              </Form>
            </Segment>
          </Grid.Column>
          {'image' in formData && (
            <Grid.Column width={6}>
              <Segment>
                <FileUpload image={formData.image} onUpload={handleOnUpload} />
              </Segment>
            </Grid.Column>
          )}
        </Grid>
      </Segment>
    </MainLayout>
  );
}

export default {
  component: Page,
  exact: false,
  public: false,
  path: '/page/:pageId',
};
